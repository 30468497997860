import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import Styled from "styled-components";
import Layout from "@components/Layout";
import { Section } from "@components/StyledComponents";

const Bio = Styled.div`
    width: 90%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 3.5rem;
    grid-template-areas:
    "cassandra bio";
    @media screen and (max-width: 600px) {
        grid-template-areas:
        "cassandra"
        "bio";
    }
`;
const Info = Styled.div`
    grid-area: bio;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    h2 {
        font-family: ${({ theme }) => theme.fontPrimary};
    }
    p {
        font-family: ${({ theme }) => theme.fontSecondary};
    }
`;
const Cassandra = Styled(Img)`
    grid-area: cassandra;
    max-width: 800px;
`;
const AboutPage = ({ data }) => {
  const header = data.header.childImageSharp.fluid;
  const bio = data.bio.childImageSharp.fluid;
  return (
    <Layout
      seoTitle="About"
      seoDescription="About Cassandra Lee & Co"
      fluid={header}
      alt="About Cassandra Lee & Co"
      headerTitle="Buy.Sell.Invest"
      headerSubTitle="Your home is my commitment"
    >
      <Section>
        <h2>About Me</h2>
        <hr />
        <Bio>
          <Cassandra fluid={bio} alt="Cassandra Lee" />
          <Info>
            <h2>Cassandra Lee</h2>
            <p>
              Accomplished, analytical, detail oriented, a resourceful Realtor with a proven track record to helping people like you buy & sell homes
              here in St George
            </p>
          </Info>
        </Bio>
      </Section>
    </Layout>
  );
};

export const query = graphql`
  query AboutQuery {
    header: file(relativePath: { eq: "unsplash_2_1200x800.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bio: file(relativePath: { eq: "cassandra_1125x1125.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1125) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

export default AboutPage;
